import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Answer } from '@shared/models/answer';
import { CandidateParticipation, TransferMode } from '@shared/models/candidate-participation';
import { CandidateCommunicationActionEnum } from '@shared/models/enums/candidate-communication-action.enum';
import { StatusParticipationEnum } from '@shared/models/enums/status-participation.enum';
import { MessageBody } from '@shared/models/grouped-message';
import { Mail } from '@shared/models/mail';
import { OpsEventGroupedMessageResponseInterface } from '@shared/models/notifications/interfaces/ops-event-grouped-message-response.interface';
import { OpsEventStatusResponseInterface } from '@shared/models/notifications/interfaces/ops-event-status-response.interface';
import { Sms } from '@shared/models/sms';
import { MessageTypeEnum, TextTemplate } from '@shared/models/sms-templates/text-template';
import { Transition } from '@shared/models/transition';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CandidateDetailService {
  private readonly baseUrl = `${environment.api.opsEvent}/api/candidate`;
  private readonly baseUrlV2 = `${environment.api.opsEvent}/v2/candidate`;

  readonly #http = inject(HttpClient);

  getCandidate(candidateId: string, eventId: string): Observable<CandidateParticipation> {
    return this.#http
      .get(`${this.baseUrl}/${candidateId}/participation/${eventId}`)
      .pipe(map((candidate) => new CandidateParticipation(candidate)));
  }

  getCandidateToken(candidateId: string, eventId: string): Observable<{ token: string }> {
    return this.#http.get<{ token: string }>(`${this.baseUrl}/${candidateId}/participation/${eventId}/token`);
  }

  getAllowedTransition(): Observable<Transition[]> {
    return this.#http
      .get<Record<string, unknown>[]>(`${this.baseUrl}/participation/transitions`)
      .pipe(map((transitions) => transitions.map((item) => new Transition(item))));
  }

  updateCandidateStatus(candidateId: string, eventId: string, status: StatusParticipationEnum): Observable<void> {
    return this.#http.get<void>(`${this.baseUrl}/${candidateId}/participation/${eventId}/transition/${status}`);
  }

  addCandidateParticipation(eventId: string, candidate: CandidateParticipation): Observable<CandidateParticipation> {
    return this.#http
      .post(`${this.baseUrl}/participation/${eventId}`, candidate)
      .pipe(map((candidateParticipation) => new CandidateParticipation(candidateParticipation)));
  }

  deleteParticipation(candidateId: string, eventId: string): Observable<void> {
    return this.#http.delete<void>(`${this.baseUrl}/${candidateId}/participation/${eventId}`);
  }

  transferCandidateParticipation(
    sourceEventId: string,
    destEventId: string,
    candidateId: string,
    mode: TransferMode
  ): Observable<CandidateParticipation> {
    return this.#http
      .post(`${this.baseUrl}/${candidateId}/participation/transfer`, {
        sourceEventId: sourceEventId,
        destinationEventId: destEventId,
        mode: mode,
      })
      .pipe(
        filter((candidateParticipation: Record<string, any>) => candidateParticipation['participations']),
        map((candidateParticipation) => new CandidateParticipation(candidateParticipation['participations']))
      );
  }

  getCandidatesByEvent(eventId: string): Observable<CandidateParticipation[]> {
    return this.#http.get<Record<string, unknown>[]>(`${this.baseUrl}/participation/by-event/${eventId}`).pipe(
      map((candidates) =>
        candidates.map((candidate) => {
          const candidateParticipation = new CandidateParticipation(candidate);
          return { ...candidateParticipation, eventId };
        })
      )
    );
  }

  getCandidatesForEventByIds(eventId: string, ids: string[]): Observable<CandidateParticipation[]> {
    if (!ids?.length) return of([]);
    return this.#http.post<CandidateParticipation[]>(`${this.baseUrl}/participation/by-event/${eventId}/by-ids`, {
      candidatesIds: ids,
    });
  }

  getCandidatesParticipationsByIds(
    participations: { candidateId: string; eventId: string }[]
  ): Observable<CandidateParticipation[]> {
    if (!participations?.length) return of([]);
    return this.#http.post<CandidateParticipation[]>(`${this.baseUrl}/participation/retrieve-from-ids`, {
      ...participations,
    });
  }

  sendCustomMail(
    eventId: string,
    candidateId: string,
    mail: { subject?: string; body: string; attachments?: Record<string, boolean>; template?: TextTemplate }
  ): Observable<unknown> {
    return this.#http.post(`${this.baseUrl}/${candidateId}/participation/${eventId}/send-custom-mail`, mail);
  }

  addSms(candidateId: string, sms: Sms, eventId: string): Observable<OpsEventStatusResponseInterface> {
    return this.#http.post<OpsEventStatusResponseInterface>(
      `${this.baseUrl}/${candidateId}/participation/${eventId}/sms`,
      sms
    );
  }

  sendGroupedSms(
    event: { body: string; recipientsStatus: string[] },
    eventId: string
  ): Observable<OpsEventGroupedMessageResponseInterface> {
    return this.#http.post<OpsEventGroupedMessageResponseInterface>(
      `${environment.api.opsEvent}/api/events/${eventId}/sms`,
      {
        body: event.body,
        recipientsStatus: event.recipientsStatus,
      }
    );
  }

  sendGroupedMail(
    event: { templateSelected: TextTemplate; body: MessageBody; recipientsStatus: string[] },
    eventId: string
  ): Observable<OpsEventGroupedMessageResponseInterface> {
    return this.#http.post<OpsEventGroupedMessageResponseInterface>(
      `${environment.api.opsEvent}/api/events/${eventId}/mail`,
      {
        subject: event.body.subject,
        content: event.body.content,
        messageType: event.templateSelected?.messageType || MessageTypeEnum.custom,
        attachments: event.body.attachments,
        statuses: event.recipientsStatus,
      }
    );
  }

  addComment(candidateId: string, comment: string, eventId: string): Observable<OpsEventStatusResponseInterface> {
    return this.#http.post<OpsEventStatusResponseInterface>(
      `${this.baseUrl}/${candidateId}/participation/${eventId}/comment`,
      { comment }
    );
  }

  editComment(candidateId: string, eventId: string, commentId: string, commentContent: string): Observable<void> {
    return this.#http.patch<void>(`${this.baseUrl}/${candidateId}/participation/${eventId}/comment/${commentId}`, {
      commentContent,
    });
  }

  deleteComment(candidateId: string, eventId: string, commentId: string): Observable<void> {
    return this.#http.delete<void>(`${this.baseUrl}/${candidateId}/participation/${eventId}/comment/${commentId}`);
  }

  updateCandidateParticipation(
    candidate: CandidateParticipation,
    eventId: string
  ): Observable<OpsEventStatusResponseInterface> {
    return this.#http.put<OpsEventStatusResponseInterface>(
      `${this.baseUrl}/${candidate.id}/participation/${eventId}`,
      candidate
    );
  }

  updateCandidateCv(
    candidateId: string,
    cvFileId: string,
    eventId: string
  ): Observable<OpsEventStatusResponseInterface> {
    return this.#http.put<OpsEventStatusResponseInterface>(
      `${this.baseUrlV2}/${candidateId}/participation/${eventId}/resume-file`,
      cvFileId
    );
  }

  postCandidateAction(
    candidateId: string,
    eventId: string,
    type: CandidateCommunicationActionEnum,
    comment = ''
  ): Observable<OpsEventStatusResponseInterface> {
    const dataToSend = { type, comment };

    if (!comment) {
      delete dataToSend.comment;
    }

    return this.#http.post<OpsEventStatusResponseInterface>(
      `${this.baseUrl}/${candidateId}/participation/${eventId}/action`,
      dataToSend
    );
  }

  assignTas(eventId: string, tasList: string[], nbCandidates: number): Observable<OpsEventStatusResponseInterface> {
    return this.#http.post<OpsEventStatusResponseInterface>(`${this.baseUrl}/assign/${eventId}`, {
      tasIds: tasList,
      nbCandidate: nbCandidates,
    });
  }

  getMailMessage(messageId: string): Observable<Mail> {
    return this.#http
      .get<Record<string, unknown>>(`${environment.api.message}/api/inbound-email/${messageId}`)
      .pipe(map((mail) => new Mail(mail)));
  }

  sendAnswers(candidateId: string, eventId: string, answers: Answer[]): Observable<any> {
    return this.#http.post(`${environment.api.opsEvent}/v2/event/${eventId}/candidate/${candidateId}/answers`, {
      answers: answers,
    });
  }

  sendAnswer(candidateId: string, eventId: string, answer: Answer): Observable<OpsEventStatusResponseInterface> {
    return this.#http.post<OpsEventStatusResponseInterface>(
      `${this.baseUrl}/${candidateId}/participation/${eventId}/answer`,
      answer
    );
  }

  removeAnswer(candidateId: string, eventId: string, questionId: string): Observable<OpsEventStatusResponseInterface> {
    return this.#http.delete<OpsEventStatusResponseInterface>(
      `${this.baseUrl}/${candidateId}/participation/${eventId}/answer/${questionId}`
    );
  }

  downloadInvitationDocument(candidateId: string, eventId: string): void {
    window.open(`${this.baseUrl}/${candidateId}/invitation/invit-${eventId}.pdf`, '_blank');
  }
}
